import { Tabs } from "../Tabs/Tabs"
import { TabContent } from "../Tab/TabContent"
import { Info } from "../Info/Info"
import { Upload } from "../Upload/Upload"
import { UpdateStoksOld } from "../UpdateStoksOld/UpdateStoks"
import { NewOrders, getOrdersState } from "../NewOrders/NewOrders"
import { Supplies } from "../Supplies/Supplies"
import { UpdateStoksIcon } from "../UpdateStoksIcon/UpdateStoksIcon"
import { Settings, updateSettingsState } from "../Settings/Settings"
import { UpdateStoksKatren } from "../UpdateStoksKatren/UpdateStoksKatren"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { UpdateStoksPodruzhka } from "../UpdateStoksPodrugka/UpdateStoksPodruzhka"
import { selectActiveShops } from "../../app/reducers/settingsReducer"
import { UpdateStoksMetro } from "../UpdateStoksMetro/UpdateStoksMetro"

export const Home = ({}) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getOrdersState())
    dispatch(updateSettingsState())
  }, [])

  const activeShops = useAppSelector(selectActiveShops)
  const [katrenShop, setKatrenShop] = useState<string>()
  const shops = (activeShops || [])
    .map((shopName) => {
      switch (shopName) {
        case "KATREN":
        case "KATREN_OZON":
          if (!katrenShop) setKatrenShop(shopName)
          if (katrenShop !== shopName) return null
          return (
            <TabContent name={"Обновить остатки Katren"}>
              <UpdateStoksKatren />
            </TabContent>
          )
        case "ICON":
          return (
            <TabContent name={"Обновить остатки ICON"}>
              <UpdateStoksIcon />
            </TabContent>
          )
        case "PODRUZHKA":
          return (
            <TabContent name={"Обновить остатки Подружка"}>
              <UpdateStoksPodruzhka />
            </TabContent>
          )
        case "METRO":
          return (
            <TabContent name={"Обновить остатки Metro"}>
              <UpdateStoksMetro />
            </TabContent>
          )
        default:
          return null
      }
    })
    .filter(Boolean)

  return (
    <div className="homePage">
      <div className="SHOP_NAME">МАГАЗИН БАБКОВ</div>
      <Tabs>
        {/* <TabContent name={"Поиск по артикулам"}>
          <Info />
        </TabContent> */}
        {/* <TabContent name={"Загрузка карточек"}>
          <Upload />
        </TabContent> 
        <TabContent name={"Обновить остатки Katren Old"}>
          <UpdateStoksOld />
        </TabContent>
        */}
        {...shops}
        <TabContent name={"Сборочные задания"}>
          <NewOrders />
        </TabContent>
        <TabContent name={"Поставки"}>
          <Supplies />
        </TabContent>
        <TabContent name={"Настройки"}>
          <Settings />
        </TabContent>
      </Tabs>
    </div>
  )
}
