import { ChangeEvent, useState, useEffect } from "react"
import s from "./UpdateStoks.module.css"
import { AppDispatch } from "../../app/store"
import BaseApi from "../../api/api"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { NotificationService } from "../../features/NotificationService/NotificationService"
import { Modal } from "../../features/Modal/Modal"
import { setUpdateStoksPodruzhkaState } from "../../app/reducers/updateStoksPodruzhka"
import { selectUpdateStoksPodruzhkaStore } from "../../app/reducers/updateStoksPodruzhka"
import { selectOrdersStore } from "../../app/reducers/ordersReducer"
import readXlsxFile from "read-excel-file"
import { windowLock } from "../../utils/loader"
import { Tabs } from "../Tabs/Tabs"
import { TabContent } from "../Tab/TabContent"
import { RecodePodrTip } from "./podrTips"
import { PodrOrders } from "./PodrOrders"

const podruzhkaUpdateStoks = (
  selectedStorageId: string,
  updatePrices: boolean,
  updateStocks: boolean,
) => {
  return async (dispatch: AppDispatch) => {
    BaseApi.podruzhkaUpdateStoks(selectedStorageId, updatePrices, updateStocks)
      .then((response) => {
        if (response.data) {
          response.data?.state &&
            dispatch(setUpdateStoksPodruzhkaState(response.data.state))
          NotificationService.showNotification({
            message: response.data.message,
            level: (response.data.success && "success") || "error",
          })
        }
      })
      .catch((error) => {
        error?.response?.data?.state &&
          dispatch(setUpdateStoksPodruzhkaState(error.response.data.state))
      })
  }
}

export const updateStoksPodruzhkaState = () => {
  return async (dispatch: AppDispatch) => {
    BaseApi.podruzhkaUpdateStoksState()
      .then((response) => {
        if (response.data) {
          dispatch(setUpdateStoksPodruzhkaState(response.data))
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

const deleteCurrentDataPodruzhka = () => {
  return async (dispatch: AppDispatch) => {
    BaseApi.deleteCurrentDataPodruzhka()
      .then((response) => {
        response.data?.state &&
          dispatch(setUpdateStoksPodruzhkaState(response.data.state))
        NotificationService.showNotification({
          message: response.data.message,
          level: (response.data.success && "success") || "error",
        })
      })
      .catch((error) => {
        console.error(error)
        error?.response?.data?.state &&
          dispatch(setUpdateStoksPodruzhkaState(error.response.data.state))
      })
  }
}

export const uploadCurrentDataPodruzhka = (file: File) => {
  return async (dispatch: AppDispatch) => {
    windowLock()

    try {
      const dataFromXslx = await readXlsxFile<{
        Id: string
        GoodCode: string
        sku: string
        count: number
        nmId: number
        productId: string
      }>(file, {
        schema: {
          Id: {
            prop: "Id",
            type: String,
            required: true,
          },
          GoodCode: {
            prop: "GoodCode",
            type: String,
            required: true,
          },
          sku: {
            prop: "sku",
            type: String,
            required: true,
          },
          count: {
            prop: "count",
            type: Number,
            required: true,
          },
          nmId: {
            prop: "nmId",
            type: Number,
            required: true,
          },
          productId: {
            prop: "productId",
            type: String,
            required: false,
          },
        },
      })

      const jsonData = dataFromXslx.rows

      BaseApi.uploadCurrentDataPodruzhka(jsonData)
        .then((response) => {
          response.data?.state &&
            dispatch(setUpdateStoksPodruzhkaState(response.data.state))
          NotificationService.showNotification({
            message: response.data.message,
            level: (response.data.success && "success") || "error",
          })
        })
        .catch((error) => {
          console.error(error)
          error?.response?.data?.state &&
            dispatch(setUpdateStoksPodruzhkaState(error.response.data.state))
        })
    } catch (error) {
      console.error("Error reading file:", error)
      NotificationService.showNotification({
        message: "Ошибка при чтении файла",
        level: "error",
      })
    }
  }
}

export const UpdateStoksPodruzhka = () => {
  const dispatch = useAppDispatch()
  const [selectedStorageId, setSelectedStorages] = useState("")
  const [isOpenModalUpdatePrices, setOpenModalUpdatePrices] = useState(false)
  const [updatePrices, setUpdatePrices] = useState(false)
  const [updateStocks, setUpdateStocks] = useState(false)

  useEffect(() => {
    dispatch(updateStoksPodruzhkaState())
  }, [])

  const updateStoksStore = useAppSelector(selectUpdateStoksPodruzhkaStore)
  const ordersStore = useAppSelector(selectOrdersStore)
  const { lastStoksUpdated, lastPricesUpdated, status, isCurrentDataUploaded } =
    updateStoksStore.wildberries || {}
  const { storages } = ordersStore

  const handleUpdate = () => {
    if (
      (updatePrices || updateStocks) &&
      isCurrentDataUploaded &&
      selectedStorageId
    ) {
      setOpenModalUpdatePrices(true)
    }
  }

  return (
    <div className={s.updateStoksWrapper}>
      <Tabs>
        <TabContent name="Обновление остатков и цен Подружка">
          <div>
            <h5>Обновление остатков и цен Подружка</h5>
            {lastPricesUpdated && (
              <h6>
                Последнее обновление цен:{" "}
                {new Date(lastPricesUpdated).toLocaleString()}
              </h6>
            )}
            {lastStoksUpdated && (
              <h6>
                Последнее обновление остатков:{" "}
                {new Date(lastStoksUpdated).toLocaleString()}
              </h6>
            )}
            {status && <h6>Статус: {status}</h6>}

            <div className={s.uploadFileWrap}>
              {isCurrentDataUploaded ? (
                <div>
                  <button
                    onClick={() => {
                      dispatch(deleteCurrentDataPodruzhka())
                    }}
                  >
                    удалить файл перекодировки
                  </button>
                </div>
              ) : (
                <div>
                  <label className={s.ktrnFileLabel} htmlFor={s.ktrnFileInp}>
                    Загрузить файл перекодировки
                  </label>
                  <input
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const file = e.target.files?.[0]
                      if (file) {
                        dispatch(uploadCurrentDataPodruzhka(file))
                      }
                    }}
                    type="file"
                    accept=".xlsx"
                    id={s.ktrnFileInp}
                  />
                </div>
              )}
              <RecodePodrTip />
            </div>

            <div className={s.dropdown}>
              <label htmlFor="storages-select">Склад для обновления</label>
              <select
                name="stock"
                id="storages-select"
                value={selectedStorageId}
                onChange={(e) => {
                  setSelectedStorages(e.target.value)
                }}
              >
                <option value={""}>Выбрать склад</option>
                {storages.map((storage) => (
                  <option value={storage.id} key={storage.name}>
                    {storage.name}
                  </option>
                ))}
              </select>
            </div>
            <div className={s.checkboxContainer}>
              <label>
                <input
                  type="checkbox"
                  checked={updatePrices}
                  onChange={(e) => setUpdatePrices(e.target.checked)}
                />
                Обновить цены
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={updateStocks}
                  onChange={(e) => setUpdateStocks(e.target.checked)}
                />
                Обновить остатки
              </label>
            </div>
            <button
              className={s.updateButton}
              disabled={
                !isCurrentDataUploaded ||
                !selectedStorageId ||
                (!updatePrices && !updateStocks)
              }
              onClick={handleUpdate}
            >
              Обновить Подружка
            </button>
          </div>
        </TabContent>
        <TabContent name="История заказов">
          <div>
            <PodrOrders />
          </div>
        </TabContent>
      </Tabs>

      <Modal
        isOpen={isOpenModalUpdatePrices}
        header="Подтвердите действие. Обновление на Подружка"
        content={`Обновить ${updatePrices ? "цены" : ""} ${
          updatePrices && updateStocks ? "и" : ""
        } ${updateStocks ? "остатки" : ""} для склада ${
          storages.find((el) => el.id === +selectedStorageId)?.name
        }`}
        onClose={() => setOpenModalUpdatePrices(false)}
        onOk={() => {
          selectedStorageId &&
            dispatch(
              podruzhkaUpdateStoks(
                selectedStorageId,
                updatePrices,
                updateStocks,
              ),
            )
          setOpenModalUpdatePrices(false)
        }}
      />
    </div>
  )
}
